<template>
  <div class="offer-direction-inline-section tw-bg-white tw-py-8 tw-px-10 tw-flex-col tw-w-full">
    <div class="tw-flex tw-mb-4">
      <offer-section-title
        :title="$t('pickup') | capitalize"
        class="tw-w-1/2 tw-mr-12"
      >
        <ui-ctk-icon
          name="marker"
          class="tw-text-pickup tw-text-4xl"
        />
      </offer-section-title>
      <offer-section-title
        :title="$t('delivery') | capitalize"
        class="tw-w-1/2 tw-ml-4"
      >
        <ui-ctk-icon
          name="marker"
          class="tw-text-delivery tw-text-4xl"
        />
      </offer-section-title>
    </div>
    <div class="tw-flex">
      <offer-group-item
        :title="$t('offers.labels.address')"
        class="tw-mb-1 tw-mr-12 tw-w-1/2"
      >
        <offer-direction-address
          :address="offer.pickup.address"
        />
      </offer-group-item>
      <offer-group-item
        :title="$t('offers.labels.address')"
        class="tw-mb-1 tw-w-1/2 tw-ml-4"
      >
        <offer-direction-address
          :address="offer.delivery.address"
        />
      </offer-group-item>
    </div>

    <div class="tw-flex">
      <div class="tw-w-1/2 tw-mr-12">
        <offer-specificity-group>
          <offer-specificity-item
            v-if="pickupAdditionalStep"
            :icon="'destination'"
            :title="$t('app.labels.additional_step.pickup')"
            :variant="'info'"
            :content="pickupAdditionalStep.comment"
            data-test="additional-step-pickup"
          />
          <offer-specificity-item
            v-if="offer.pickup.comment"
            :icon="'comment'"
            :title="$t('comment')"
            :content="offer.pickup.comment"
            :variant="'info'"
            data-test="pickup-comment"
          />
          <offer-accessibility
            :offer="offer"
            :direction="'pickup'"
            data-test="pickup-accessibility"
          />
        </offer-specificity-group>
      </div>
      <div class="tw-w-1/2 tw-ml-4">
        <offer-specificity-group>
          <offer-specificity-item
            v-if="deliveryAdditionalStep"
            :icon="'destination'"
            :title="$t('app.labels.additional_step.delivery')"
            :variant="'primary'"
            :content="deliveryAdditionalStep.comment"
            data-test="additional-step-delivery"
          />
          <offer-specificity-item
            v-if="offer.delivery.comment"
            :icon="'comment'"
            :title="$t('comment')"
            :content="offer.delivery.comment"
            :variant="'primary'"
            data-test="delivery-comment"
          />
          <offer-accessibility
            :offer="offer"
            :direction="'delivery'"
            data-test="delivery-accessibility"
          />
        </offer-specificity-group>
      </div>
    </div>

    <div class="tw-flex">
      <div
        class="tw-w-1/2 tw-mr-12"
      >
        <offer-timeslots
          :offer="offer"
          :direction="'pickup'"
          class="tw-mb-6"
        />
        <offer-specificity-group>
          <offer-specificity-item
            v-if="!!pickupRdv"
            :icon="'scheduled'"
            :title="$t('app.labels.rdv_option.pickup')"
            :variant="'info'"
            :content="pickupRdv"
            data-test="rdv-pickup"
          />
        </offer-specificity-group>
      </div>
      <div
        class="tw-w-1/2 tw-ml-4"
      >
        <offer-timeslots
          :offer="offer"
          :direction="'delivery'"
          class="tw-mb-6"
        />
        <offer-specificity-group>
          <offer-specificity-item
            v-if="!!deliveryRdv"
            :icon="'scheduled'"
            :title="$t('app.labels.rdv_option.delivery')"
            :variant="'primary'"
            :content="deliveryRdv"
            data-test="rdv-delivery"
          />
        </offer-specificity-group>
      </div>
    </div>
    <div class="tw-flex">
      <div
        class="tw-w-1/2 tw-mr-12"
      >
        <offer-options
          :offer="offer"
          :direction="'pickup'"
        />
      </div>
      <div
        class="tw-w-1/2 tw-ml-4"
      >
        <offer-options
          :offer="offer"
          :direction="'delivery'"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, defineComponent, toRefs } from '@vue/composition-api'

  import OfferGroupItem from '@/views/Carriers/Offers/components/OfferDetail/components/OfferGroupItem/index.vue'
  import OfferSectionTitle from '@/views/Carriers/Offers/components/OfferDetail/components/OfferSection/OfferSectionTitle/index.vue'
  import OfferDirectionAddress from '@/views/Carriers/Offers/components/OfferDetail/components/OfferDirectionsSection/_subs/OfferDirectionAddress/index.vue'
  import OfferOptions from '@/views/Carriers/Offers/components/OfferDetail/components/OfferDirectionsSection/_subs/OfferOptions/index.vue'
  import OfferTimeslots from '@/views/Carriers/Offers/components/OfferDetail/components/OfferDirectionsSection/_subs/OfferTimeslots/index.vue'
  import OfferAccessibility from '@/views/Carriers/Offers/components/OfferDetail/components/OfferDirectionsSection/_subs/OfferAccessibility/index.vue'
  import OfferSpecificityGroup from '@/views/Carriers/Offers/components/OfferDetail/components/OfferSpecificityGroup/index.vue'
  import OfferSpecificityItem from '@/views/Carriers/Offers/components/OfferDetail/components/OfferSpecificityGroup/_subs/OfferSpecificityItem/index.vue'

  import { useMeetingComment } from '@/composables/carriers/offers/useMeeting'

  export default defineComponent({
    name: 'OfferDirectionInlineSection',
    components: {
      OfferGroupItem,
      OfferDirectionAddress,
      OfferSectionTitle,
      OfferOptions,
      OfferTimeslots,
      OfferAccessibility,
      OfferSpecificityGroup,
      OfferSpecificityItem
    },
    props: {
      offer: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      const { offer } = toRefs(props)

      /**
       * TODO: Like the useMeetingComment, move this function as a composable as it may be
       * used by other components.
       * @function getPriceLine
       * @param {string} lineKey
       * @returns {{
       *   key: string
       * } | undefined}
       */
      function getPriceLine (lineKey) {
        if (!offer.value.pricing || !offer.value.pricing.price_lines) return

        // @ts-ignore
        return offer.value.pricing?.price_lines?.find(line => line.key === lineKey)
      }

      const pickupRdv = computed(() => useMeetingComment(offer.value.pickup))
      const deliveryRdv = computed(() => useMeetingComment(offer.value.delivery))
      const pickupAdditionalStep = computed(() => getPriceLine('pickup_additional_step'))
      const deliveryAdditionalStep = computed(() => getPriceLine('delivery_additional_step'))

      return {
        pickupRdv,
        deliveryRdv,
        pickupAdditionalStep,
        deliveryAdditionalStep
      }
    }
  })
</script>

<style lang="scss" scoped>
.offer-direction-inline-section {
  position: relative;
}
.offer-direction-inline-section::after {
  --tw-border-opacity: 1;
  border-color: rgba(214, 214, 218, var(--tw-border-opacity));
  border-style: solid;
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 0px;
  position: absolute;
  content: '';
  height: 80%;
  height: calc(100% - 8rem);
  width: 1px;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-bottom: 2rem;
}
</style>
