<template>
  <offer-section
    class="offer-direction-section tw-flex tw-flex-col"
  >
    <template
      #title
    >
      <offer-section-title
        :title="$t(direction === 'pickup' ? 'pickup' : 'delivery') | capitalize"
      >
        <ui-ctk-icon
          name="marker"
          :class="[direction === 'pickup' ? 'tw-text-pickup' : 'tw-text-delivery']"
          class="tw-text-4xl"
        />
      </offer-section-title>
    </template>

    <offer-group-item
      :title="$t('offers.labels.address')"
      class="tw-mb-5"
      data-test="address"
    >
      <offer-direction-address
        :address="step.address"
      />
    </offer-group-item>

    <offer-specificity-group
      data-test="address-specificities"
    >
      <offer-specificity-item
        v-if="additionalStep"
        :icon="'destination'"
        :title="$t(direction === 'pickup'
          ? 'app.labels.additional_step.pickup'
          : 'app.labels.additional_step.delivery')"
        :variant="direction === 'pickup' ? 'info' : 'primary'"
        :content="additionalStep.comment"
        data-test="additional-step"
      />
      <offer-specificity-item
        v-if="step.comment"
        :icon="'comment'"
        :title="$t('comment')"
        :content="step.comment"
        :variant="direction === 'pickup' ? 'info' : 'primary'"
        data-test="comment"
      />
      <offer-accessibility
        :offer="offer"
        :direction="direction"
        data-test="accessibility"
      />
    </offer-specificity-group>

    <offer-timeslots
      :offer="offer"
      :direction="direction"
      class="tw-mb-4"
      data-test="timeslots"
    />

    <offer-specificity-group
      data-test="date-specificities"
    >
      <offer-specificity-item
        v-if="!!rdv"
        :icon="'scheduled'"
        :title="$t(direction === 'pickup'
          ? 'app.labels.rdv_option.pickup'
          : 'app.labels.rdv_option.delivery')"
        :variant="direction === 'pickup' ? 'info' : 'primary'"
        :content="rdv"
        data-test="rdv"
      />
    </offer-specificity-group>

    <offer-options
      :offer="offer"
      :direction="direction"
      data-test="options"
    />
  </offer-section>
</template>

<script>
  import { defineComponent, computed, toRefs } from '@vue/composition-api'

  import OfferSection from '@/views/Carriers/Offers/components/OfferDetail/components/OfferSection/index.vue'
  import OfferSectionTitle from '@/views/Carriers/Offers/components/OfferDetail/components/OfferSection/OfferSectionTitle/index.vue'
  import OfferGroupItem from '@/views/Carriers/Offers/components/OfferDetail/components/OfferGroupItem/index.vue'
  import OfferDirectionAddress from '@/views/Carriers/Offers/components/OfferDetail/components/OfferDirectionsSection/_subs/OfferDirectionAddress/index.vue'
  import OfferOptions from '@/views/Carriers/Offers/components/OfferDetail/components/OfferDirectionsSection/_subs/OfferOptions/index.vue'
  import OfferTimeslots from '@/views/Carriers/Offers/components/OfferDetail/components/OfferDirectionsSection/_subs/OfferTimeslots/index.vue'
  import OfferAccessibility from '@/views/Carriers/Offers/components/OfferDetail/components/OfferDirectionsSection/_subs/OfferAccessibility/index.vue'
  import OfferSpecificityGroup from '@/views/Carriers/Offers/components/OfferDetail/components/OfferSpecificityGroup/index.vue'
  import OfferSpecificityItem from '@/views/Carriers/Offers/components/OfferDetail/components/OfferSpecificityGroup/_subs/OfferSpecificityItem/index.vue'

  import { useMeetingComment } from '@/composables/carriers/offers/useMeeting'

  export default defineComponent({
    name: 'OfferDirectionSection',
    components: {
      OfferSection,
      OfferSectionTitle,
      OfferGroupItem,
      OfferDirectionAddress,
      OfferOptions,
      OfferTimeslots,
      OfferAccessibility,
      OfferSpecificityGroup,
      OfferSpecificityItem
    },
    props: {
      offer: {
        type: Object,
        required: true
      },
      direction: {
        type: String,
        required: true
      }
    },
    setup (props) {
      const { offer, direction } = toRefs(props)
      const step = computed(() => offer.value[direction.value])

      /**
       * @function getPriceLine
       * @param {string} lineKey
       * @returns {{
       *   key: string
       * } | undefined}
       */
      function getPriceLine (lineKey) {
        if (!offer.value.pricing || !offer.value.pricing.price_lines) return

        // @ts-ignore
        return offer.value.pricing?.price_lines?.find(line => line.key === lineKey)
      }

      const rdv = computed(() => useMeetingComment(offer.value[direction.value]))
      const additionalStep = computed(() => getPriceLine(direction.value === 'pickup'
        ? 'pickup_additional_step'
        : 'delivery_additional_step'))

      return {
        rdv,
        additionalStep,
        step
      }
    }
  })
</script>
